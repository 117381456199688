const menuTrigger = document.querySelector(`.js-menu-trigger`);
const emptyHashes = [...document.querySelectorAll(`[href="#"]`)];

if (menuTrigger) {
  menuTrigger.addEventListener('click', () => {
    document.body.classList.toggle('js-menu-active');
  });
}

if (emptyHashes) {
  for (let index = 0; index < emptyHashes.length; index += 1) {
    emptyHashes[index].addEventListener('click', (e) => {
      e.preventDefault();
    });
  }
}

// Fix for hover menu on mobile
document.addEventListener('DOMContentLoaded', () => {
  const isMobile = window.matchMedia(
    'only screen and (max-width: 768px)',
  ).matches;

  if (isMobile) {
    const menuItems = document.querySelectorAll('.c-main-nav__item');

    menuItems.forEach((item) => {
      const link = item.querySelector('.c-main-nav__link');
      const subMenu = item.querySelector('.c-main-nav__dropdown');

      if (subMenu) {
        link.addEventListener('click', (event) => {
          if (
            !link.parentElement.classList.contains('js-menuItem-active') ||
            link.attributes.href.value === '#'
          ) {
            event.preventDefault();
            link.parentElement.classList.toggle('js-menuItem-active');
          }
        });
      }
    });
  }
});
